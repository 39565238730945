import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DfModalService } from '@design-factory/design-factory';
import { Store, select } from '@ngrx/store';
import { Analytics } from '@seco/core';
import { Observable, filter } from 'rxjs';
import { SessionInformationComponent } from './session-information/session-information.component';
import { LocalesService, SupportedLocale } from './services/locales/locales.service';
import { AlertService } from './services/alert/alert.service';
import { UtilService } from './services/util/util.service';
import { AlertModel } from './model/alert.model';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { ClpInitConfigExtended } from './model/clpInitConfigExtended.model';
import { selectClpConfig } from './redux/core.reducer';

const SUPPORT_PATH = 'https://servicehub.amadeus.com/c/portal/view-solution/757691800';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: { class: 'h-100 d-flex flex-column' }
})
export class AppComponent {
  availableLanguages: { languageName: string; locale: SupportedLocale }[];
  currentLanguage: { languageName: string; locale: SupportedLocale };
  globalAlerts$: Observable<AlertModel[]>;
  clpConfig$: Observable<ClpInitConfigExtended | undefined>;
  displayFooter: boolean;

  constructor(
    private readonly localesService: LocalesService,
    private readonly alertService: AlertService,
    private readonly utilService: UtilService,
    readonly router: Router,
    private readonly modalService: DfModalService,
    private readonly store: Store,
    private readonly analytics: Analytics
  ) {
    this.availableLanguages = this.localesService.getAvailableLanguages();
    this.currentLanguage = {
      locale: this.localesService.getDefaultLocaleUsed(),
      languageName: this.localesService.getLanguageName()
    };
    this.globalAlerts$ = this.alertService.getAlerts();

    this.clpConfig$ = this.store.select(selectClpConfig);
    this.displayFooter = this.isNotSso();
  }

  selectLanguage(locale: SupportedLocale): void {
    this.localesService.updatePreferedLocaleCookie(locale, true);
  }

  goToSupport(): void {
    this.utilService.redirect(SUPPORT_PATH);
    this.analytics.track({ action: 'login.requestSupport' });
  }

  goToContactLink(): void {
    this.clpConfig$.subscribe((config) => {
      if (config) {
        const contactUrl = config.loginParameters.contactUrl;
        if (contactUrl) {
          this.utilService.redirect(contactUrl);
        }
      }
    });
  }

  goToPrivacyPolicyLink(): void {
    this.clpConfig$.subscribe((config) => {
      if (config) {
        const privacyPolicyUrl = config.loginParameters.privacyPolicyUrl;
        if (privacyPolicyUrl) {
          this.utilService.redirect(privacyPolicyUrl);
        }
      }
    });
  }

  openSecurityInformationModal(): void {
    this.modalService.open(SessionInformationComponent, { centered: true });
  }

  public openLegalNotices(): void {
    this.modalService.open(CookiePolicyComponent, { centered: true, size: 'lg' });
  }

  private isNotSso(): boolean {
    return (
      window.location.pathname !== '/login/loginFromSAMLSSO' && window.location.pathname !== '/login/redirectSAMLSSO'
    );
  }
}
